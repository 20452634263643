import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HomeRoutes } from './home.routing';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { EnterpriceSassNavComponent } from './enterprice-sass-nav/enterprice-sass-nav.component';
import { EnterpriceSassHeaderComponent } from './enterprice-sass-header/enterprice-sass-header.component';
import { EnterpriceSassFeatureComponent } from './enterprice-sass-feature/enterprice-sass-feature.component';
import { EnterpriceSassWorkComponent } from './enterprice-sass-work/enterprice-sass-work.component';
import { EnterpriceSassPriceComponent } from './enterprice-sass-price/enterprice-sass-price.component';
import { EnterpriceSassTestimonialComponent } from './enterprice-sass-testimonial/enterprice-sass-testimonial.component';
import { EnterpriceSassFaqComponent } from './enterprice-sass-faq/enterprice-sass-faq.component';
import { EnterpriceSassFooterComponent } from './enterprice-sass-footer/enterprice-sass-footer.component';
import { EnterpriceSassCopyrightComponent } from './enterprice-sass-copyright/enterprice-sass-copyright.component';

import { ComponentsModule } from '../shared/components/components.module';

@NgModule({
	imports: [
		CommonModule, 
		HomeRoutes, 
		TranslateModule,
		ComponentsModule,
		RouterModule
	],
	declarations: [
		HomeComponent,
		EnterpriceSassNavComponent,
		EnterpriceSassHeaderComponent,
		EnterpriceSassFeatureComponent,
		EnterpriceSassWorkComponent,
		EnterpriceSassPriceComponent,
		EnterpriceSassTestimonialComponent,
		EnterpriceSassFaqComponent,
		EnterpriceSassFooterComponent,
		EnterpriceSassCopyrightComponent
	],
})
export class HomeModule {}
